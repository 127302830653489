import { Component } from '@angular/core';
import { StateService } from 'src/propstat/services/state.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-user-chat-list-item',
	templateUrl: './user-chat-list-item.component.html',
})
export class UserChatListItemComponent extends BaseComponent {
	public userProfilePicture: string = "../assets/img/93eaef67-d29d-4757-ae7c-22d1815a6c3e.JPG";
	
	constructor(stateService: StateService) {
		super(stateService);
	}
}
