<div class="uk-flex uk-flex-column uk-background-default uk-padding uk-border-rounded">
	<form class="" action="" >
		<fieldset class="uk-fieldset">
			<legend class="uk-legend uk-h2">Profil bearbeiten</legend>

			<div class="uk-flex uk-flex-middle">
                <img class="uk-border-circle uk-profile-card-picture"  src="../assets/img/93eaef67-d29d-4757-ae7c-22d1815a6c3e.JPG">
            </div>

			<div class="uk-margin">
				<input type="text" class="uk-input uk-border-rounded" placeholder="Benutzername"/>
			</div>
			<div class="uk-margin">
				<textarea rows="7" class="uk-textarea uk-border-rounded" placeholder="Profilbeschreibung"></textarea>
			</div>
			
            <div class="uk-margin-medium-top">
				<button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" [disabled]="isLoading$" type="submit" (click)="submit()">So bin ich zufrieden!</button>
			</div>
			
		</fieldset>
	</form>
</div>