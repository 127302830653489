import { NgModule } from '@angular/core';
import * as _ from 'lodash';

@NgModule()
export class TraversalService {
	public traverseFinalObject(paths: string[], item: any, handleUndefined: boolean = true, ignoreIndex = false): any {
		var indexer = this.getIndexer(paths[0]);
		var index = indexer != null ? (ignoreIndex ? 0 : indexer[1]) : 0;
		var newItem = indexer != null ? (item[indexer[0]] != null ? item[indexer[0]][index] : null) : item[paths[0]];

		if (newItem != null) {
			if (paths.length > 1) {
				return this.traverseFinalObject(paths.slice(1), newItem, handleUndefined);
			} else {
				return newItem;
			}
		}

		if (handleUndefined) {
			return typeof newItem === 'undefined' ? null : {};
		} else {
			return null;
		}
	}

	public traversePreFinalObject(obj: any, path: string | string[]): any {
		if (!_.isArray(path)) path = (path as string).split('.');
		if (path.length == 1) {
			return obj;
		}

		var indexer = this.getIndexer(path[0]);
		var newObj = indexer != null ? obj[indexer[0]][indexer[1]] : obj[path[0]];

		if (newObj != null) {
			if (path.length > 1) {
				return this.traversePreFinalObject(newObj, path.slice(1));
			} else {
				return newObj;
			}
		}

		return null;
	}

	private getIndexer(path: string): [string, number] {
		var indexOfIndexerStart = path.indexOf('[');
		var indexOfIndexerEnd = path.indexOf(']');

		if (indexOfIndexerStart > -1 && indexOfIndexerStart > -1) {
			var subPath = path.substr(0, indexOfIndexerStart);
			var index = parseInt(path.substring(indexOfIndexerStart + 1, indexOfIndexerEnd));
			return [subPath, index];
		}

		return null;
	}

	public traverseFinalProperty(obj: any, path: string | string[]): any {
		if (!_.isArray(path)) path = (path as string).split('.');
		return path[path.length - 1];
	}

	public setValue(obj: any, path: string | string[], value: any): void {
		var resultObj = this.traversePreFinalObject(obj, path);
		var resultProperty = this.traverseFinalProperty(obj, path);

		resultObj[resultProperty] = value;
	}
}
