import { Component } from '@angular/core';
import { StateService } from 'src/propstat/services/state.service';
import { PageComponent } from '../page.component';


@Component({
	selector: 'prop-stat-display-form-page',
	templateUrl: './display-form-page.component.html',
})
export class DisplayFormPageComponent extends PageComponent {
	
	constructor(stateService: StateService) {
		super(stateService);
	}

}
