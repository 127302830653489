<div class="uk-container uk-margin-large-top">
    <div uk-grid>

        <div class="uk-width-1-3@m">
                <prop-stat-user-profile-card></prop-stat-user-profile-card>
        </div>

        <div class="uk-width-expand@m">
            <div class=" uk-child-width-1-2@xs uk-child-width-1-2@m" uk-grid>
                    <prop-stat-display-card></prop-stat-display-card>
                    <prop-stat-display-card></prop-stat-display-card>
                    <prop-stat-display-card></prop-stat-display-card>
                    <prop-stat-display-card></prop-stat-display-card>
                    <prop-stat-display-card></prop-stat-display-card>
                    <prop-stat-display-card></prop-stat-display-card>

                </div>
        </div>
    
    </div>
</div>