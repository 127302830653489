import { Directive } from '@angular/core';
import { StateService } from '../services/state.service';
import { BaseComponent } from './base.component';

@Directive()
export abstract class PageComponent extends BaseComponent {
	constructor(stateService: StateService) {
		super(stateService);
	}
}
