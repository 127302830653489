import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { INotification } from '../interfaces/notification';

@Injectable()
export class NotificationService {
	constructor(private snackBar: MatSnackBar) {}

	public notify(notification: INotification): Promise<void> {
		const snackBar = this.snackBar.open(notification.message, notification.action, this.getConfig(notification));
		return new Promise<void>((resolve, reject) => snackBar.onAction().subscribe(() => resolve()));
	}

	private getConfig(notification: INotification): MatSnackBarConfig {
		return {
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
			duration: notification.duration ? notification.duration * 1000 : null,
		};
	}
}
