import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { IPage } from 'src/propstat/interfaces/page';
import { StateService } from 'src/propstat/services/state.service';
import { UpdateService } from 'src/propstat/services/update.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-main',
	templateUrl: './main.component.html',
})
export class MainComponent extends BaseComponent {
	public page: IPage = null;

	constructor(stateService: StateService, private updateService: UpdateService, private router: Router) {
		super(stateService);
		this.updateService.checkForUpdates(10);
		this.page = stateService.current$.page;
	}

	public onInit(): void {
		this.onChange(state => (this.page = state.page), 'page');
		this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => (this.page = null));
	}
}
