

<section class="uk-background-login-section uk-background-cover uk-padding-large">
    <div class="uk-container">
        <div class=" uk-child-width-1-2@s" uk-grid>
            <div></div>
            <prop-stat-login-form></prop-stat-login-form>
        </div>
    </div>
</section>
