import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from 'src/propstat/services/notification.service';
import { StateService } from 'src/propstat/services/state.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-display-form',
	templateUrl: './display-form.component.html',
})
export class DisplayFormComponent extends BaseComponent {
	public form: FormGroup;
	public agreement: boolean = false;
	public isLoading$: boolean = false;

	constructor(stateService: StateService, private formBuilder: FormBuilder, private notificationService: NotificationService) {
		super(stateService);
	}

	

	public submit(): void{
		/* this.isLoading$ = true;

		this.loginService
			.post({
				username: this.form.value.username,
				password: this.form.value.password,
			})
			.then()
			.catch(() => {
				this.notificationService.notify({
					message: 'Es ist etwas schief gelaufen. Bitte versuche es erneut.',
					duration: 5,
				});
			})
			.finally(() => {
				this.isLoading$ = false;
			});
	}

	protected onInit(): void {
		this.form = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
		});
	 */
	}	
}
