import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DisplayDetailPageComponent } from '../components/display-detail-page/display-detail-page.component';
import { DisplayFormPageComponent } from '../components/display-form-page/display-form-page.component';
import { DisplayPageComponent } from '../components/display-page/display-page.component';
import { HomePageComponent } from '../components/home-page/home-page.component';
import { LoginPageComponent } from '../components/login-page/login-page.component';
import { RegisterPageComponent } from '../components/register-page/register-page.component';
import { UserFavoritesPageComponent } from '../components/user-favorites-page/user-favorites-page.component';
import { UserChatComponent } from '../components/user-plant-mail-page/user-chat.component';
import { UserPlantMailPageComponent } from '../components/user-plant-mail-page/user-plant-mail-page.component';
import { UserProfileEditPageComponent } from '../components/user-profile-edit-page/user-profile-edit-page.component';
import { UserProfilePageComponent } from '../components/user-profile-page/user-profile-page.component';
import { PageGuard } from '../guards/page.guard';
import { Routes } from '../interfaces/route';



const routes: Routes = [
	{
		path: '',
		component: HomePageComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['1'],
		},
	},
	{
		path: 'register',
		component: RegisterPageComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['2'],
		},
	},
	{
		path: 'app',
		component: DisplayPageComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['3'],
		},
	},
	{
		path: 'my-favourites',
		component: UserFavoritesPageComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['4'],
		},
	},
	{
		path: 'login',
		component: LoginPageComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['5'],
		},
	},
	{
		path: 'display-create',
		component: DisplayFormPageComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['6'],
		},
	},
	{
		path: 'my-profile',
		component: UserProfilePageComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['7'],
		},
	},
	{
		path: 'edit-profile',
		component: UserProfileEditPageComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['8'],
		},
	},
	{
		path: 'plant-mail',
		component: UserPlantMailPageComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['9'],
		},
	},
	{
		path: 'app/detail',
		component: DisplayDetailPageComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['10'],
		},
	},
	{
		path: 'plant-mail/chat/username',
		component: UserChatComponent,
		canActivate: [PageGuard],
		data: {
			pageIdPath: ['11'],
		},
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class RoutingModule {}
