<div *ngIf="files.length != 5 " imageDragDrop (files)="filesDropped($event)" class="js-upload uk-placeholder uk-text-center uk-border-rounded">
    <span uk-icon="icon: cloud-upload"></span>
    <span class="uk-text-middle"> Bilder einfach hierher ziehen oder </span>
    <div uk-form-custom>
        <input type="file" (files)="filesDropped($event)" multiple>
        <span class="uk-link"> in einem Ordner suchen</span>
    </div>
</div>

<progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>

<div  class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>

    <ul  class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m" >
        <li *ngFor="let file of files" class="uk-height-medium uk-flex uk-flex-middle">
            <img *ngIf="file" class="uk-card-picture" [src]="file.url" >
            <div class="uk-position-center uk-panel"><h1></h1></div>
        </li>
    </ul>
    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

</div>