import { Directive, OnDestroy, OnInit } from '@angular/core';
import { IState } from '../interfaces/state';
import { StateService } from '../services/state.service';

@Directive()
export abstract class BaseComponent implements OnInit, OnDestroy {
	public state$: IState;

	constructor(protected stateService: StateService) {}

	protected onInit(): Promise<void> | void {}

	public ngOnInit(): void {
		this.stateService.onChange(this, state => (this.state$ = state));
		this.onInit();
	}

	public ngOnDestroy(): void {
		this.destroy();
	}

	protected destroy(): void {
		this.stateService.onDestroy(this);
	}

	protected update(setter: (state: IState) => void, notify: boolean = true): void {
		this.stateService.update(setter, notify);
	}

	protected onChange(callback: (state: IState) => void, confirm?: string | string[], ignore?: string | string[], immediate: boolean = true, notNull: boolean = false): void {
		this.stateService.onChange(this, callback, confirm, ignore, immediate, notNull);
	}
}
