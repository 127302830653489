import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/propstat/services/auth.service';
import { NotificationService } from 'src/propstat/services/notification.service';
import { RegisterService } from 'src/propstat/services/register.service';
import { StateService } from 'src/propstat/services/state.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-register-form',
	templateUrl: './register-form.component.html',
})
export class RegisterFormComponent extends BaseComponent {
	public form: FormGroup;
	public agreement: boolean = false;
	public isLoading$: boolean = false;

	constructor(stateService: StateService, private formBuilder: FormBuilder, private registerService: RegisterService, private notificationService: NotificationService, private authService: AuthService, private router: Router) {
		super(stateService);
	}

	public submit(): void{
		this.isLoading$ = true;

		this.registerService
			.register({
				username: this.form.value.username,
				email: this.form.value.email,
				password: this.form.value.password,
				confirmes: true
			})
			.then()
			.catch(() => {
				this.notificationService.notify({
					message: 'Es ist etwas schief gelaufen. Bitte versuche es erneut.',
					duration: 5,
				});
			})
			.finally(() => {
				this.isLoading$ = false;
				this.notificationService.notify({
					message: 'Wir freuen uns auf dich! Dein Aktivierungslink ist unterwegs!',
					duration: 5,
					action: 'Okay',
				});
			});
	}

	protected onInit(): void {

		if(this.authService.isLoggedIn()) {
			this.router.navigate(['/app']);
		}

		this.form = this.formBuilder.group({
			username: ['', Validators.required],
			email: ['', Validators.required],
			password: ['', Validators.required],
		});
	}
}
