import { Component } from '@angular/core';
import { ITag } from 'src/propstat/interfaces/tag';
import { StateService } from 'src/propstat/services/state.service';
import { DisplayDetailComponent } from '../display-detail.component';


@Component({
	selector: 'prop-stat-display-detail-page',
	templateUrl: './display-detail-page.component.html',
})
export class DisplayDetailPageComponent extends DisplayDetailComponent {
	
	
	public displayImages: string[] = ['../assets/img/2E3E093E-FD71-4C7C-A336-9E11DE017832.JPG', '../assets/img/2E3E093E-FD71-4C7C-A336-9E11DE017832.JPG'];
	public description: string = "Monstera deliciosa variegata Ableger. Sie erhalten einen Ableger mit Luftwurzel und 1 oder 2 Blättern. Monstera deliciosa variegata Ableger. Sie erhalten einen Ableger mit Luftwurzel und 1 oder 2 Blättern. Monstera deliciosa variegata Ableger. Sie erhalten einen Ableger mit Luftwurzel und 1 oder 2 Blättern. Monstera deliciosa variegata Ableger. Sie erhalten einen Ableger mit Luftwurzel und 1 oder 2 Blättern."
	public title: string = "Monstera deliciosa variegata Ableger";
	public price: number = 10;
	public isShippingPossible: boolean = true;
	public isTradePossible: boolean = true;
	public tagList: ITag[] = [{ "id": "1", "name": "Monstera" },
    { "id": "2", "name": "Ableger" },
    { "id": "3", "name": "Bewurzelt" }];

	constructor(stateService: StateService) {
		super(stateService);
	}

}
