<div class="uk-flex uk-flex-column uk-background-default uk-padding uk-border-rounded">
	<form class="" action="" >
		<fieldset class="uk-fieldset">
			<legend class="uk-legend uk-h2">Anzeige erstellen</legend>

			<prop-stat-image-upload></prop-stat-image-upload>

			<div class="uk-margin">
				<input type="text" class="uk-input uk-border-rounded" placeholder="Name der Pflanze"  />
			</div>
			<div class="uk-margin">
				<input type="text" class="uk-input uk-border-rounded" placeholder="Verkaufspreis"  />
			</div>
			<div class="uk-margin">
				<textarea rows="7" class="uk-textarea uk-border-rounded" placeholder="Beschreibung" ></textarea>
			</div>

			<prop-stat-tag-input></prop-stat-tag-input>

			<div class="uk-margin" uk-grid>
				<div class="uk-width-1-2">
					<label><input type="checkbox" class="uk-checkbox"  /> Ich Tausche</label>
					<span class="uk-label"><mat-icon>cached</mat-icon></span>
				</div>
				<div class="uk-width-1-2">
					<label><input type="checkbox" class="uk-checkbox" /> Versand möglich</label>
					<span class="uk-label"><mat-icon>local_shipping</mat-icon></span>
				</div>
			</div>
	
            <div class="uk-margin-medium-top">
				<button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" [disabled]=" isLoading$" type="submit" (click)="submit()">Ab auf den Markt mit dir!</button>
			</div>
			
		</fieldset>
	</form>
</div>