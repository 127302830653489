import { Component } from '@angular/core';
import { FileHandle } from 'src/propstat/directives/dragDrop.directive';
import { StateService } from 'src/propstat/services/state.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-image-upload',
	templateUrl: './image-upload.component.html',
})
export class ImageUploadComponent extends BaseComponent {
	public files: FileHandle[] = [];

	constructor(stateService: StateService) {
		super(stateService);
	}

   	public filesDropped(files: FileHandle[]): void {
    	this.files = files;
  	}
	
}
