<div class="uk-flex uk-flex-column uk-background-default uk-padding uk-border-rounded">
	<form class="" action="" [formGroup]="form">
		<fieldset class="uk-fieldset">
			<legend class="uk-legend uk-h2">Jetzt kostenlos registrieren:</legend>
			<div class="uk-margin">
				<input type="text" class="uk-input uk-border-rounded" placeholder="Benutzername" formControlName="username" />
			</div>
			<div class="uk-margin">
				<input type="email" class="uk-input uk-border-rounded" placeholder="E-Mail-Adresse" formControlName="email" />
			</div>
			<div class="uk-margin">
				<input type="password" class="uk-input uk-border-rounded" placeholder="Passwort" formControlName="password" />
            </div>
            <div>
                <div class="uk-form-controls uk-margin-medium-top">
                    <label class="text-small uk-text-muted">
                        <input class="uk-checkbox" type="checkbox" value="agreement" [checked]="agreement" (change)="agreement = !agreement" />
                        Ich habe die <a>Datenschutzbestimmungen</a> gelesen und bin damit einverstanden, dass meine persönlichen Daten zu den Zwecken, in dem Umfang und für die Dauer die in der
                        Datenschutzerklärung erläutert wurden, verarbeitet und gespeichert werden.
                    </label>
                </div>
            </div>
			<div class="uk-margin-medium-top">
				<button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" [disabled]="!form.valid || !agreement || isLoading$" type="submit" (click)="submit()">Registrieren</button>
			</div>
		</fieldset>
	</form>
</div>