import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TraversalService } from './traversal.service';

@Injectable()
export class ChangeService<T> {
	public current$: T;

	private changes: any = {};
	private innerSubject: Subject<[T, T]>;
	private outerSubject: Subject<[T, any]>;

	constructor(private traversalService: TraversalService) {
		this.innerSubject = new Subject();
		this.outerSubject = new Subject();

		this.innerSubject.subscribe(([oldItem, newItem]) => {
			let difference = this.difference(newItem, oldItem);
			this.changes = this.mergeDeep(this.changes, difference);
		});

		this.innerSubject.pipe(debounceTime(200)).subscribe(([oldItem, newItem]) => {
			if (_.size(this.changes) > 0) {
				this.outerSubject.next([newItem, this.changes]);
				this.changes = {};
			}
		});
	}

	public next(oldItem: T, newItem: T): void {
		var cloneNewItem = _.cloneDeep(newItem);
		this.current$ = cloneNewItem;

		this.innerSubject.next([oldItem, newItem]);
	}

	public subscribe(changeDefinition: ChangeDefinition<T>): Subscription {
		var cloneCurrentItem = _.cloneDeep(this.current$);
		if (changeDefinition.immediate == null || changeDefinition.immediate) {
			changeDefinition.callback(cloneCurrentItem);
		}

		var subscription = this.outerSubject.subscribe(([newItem, changes]) => {
			var cloneNewItem = _.cloneDeep(newItem);

			if (changeDefinition.ignore != null || changeDefinition.confirm != null) {
				var ignored = changeDefinition.ignore != null ? this.ignore(changeDefinition.ignore, changes, cloneNewItem, changeDefinition.notNull) : false;
				var confirmed = changeDefinition.confirm != null ? this.confirm(changeDefinition.confirm, changes, cloneNewItem, changeDefinition.notNull) : false;

				if (ignored || confirmed) {
					changeDefinition.callback(cloneNewItem);
				}
			} else {
				changeDefinition.callback(cloneNewItem);
			}
		});

		return subscription;
	}

	private confirm(props: string | string[], changes: any, item: T, notNull: boolean = false): boolean {
		props = this.makeArray(props);

		for (var i = 0; i < props.length; i++) {
			var thisChanges = _.cloneDeep(changes);
			var paths = props[i].split('.');
			var hasChanges = this.traversalService.traverseFinalObject(paths, thisChanges, true, true);

			if (hasChanges != null) {
				if (notNull) {
					var thisItem = _.cloneDeep(item);
					var result = this.traversalService.traverseFinalObject(paths, thisItem, false, true);

					return result != null;
				}

				return true;
			}
		}

		return false;
	}

	private difference(object, base): any {
		if (base == null) {
			return object;
		}

		var changes = (object, base) => {
			return _.transform(object, (result, value, key) => {
				if (!_.isEqual(value, base[key])) {
					result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
				}
			});
		};

		return changes(object, base);
	}

	private ignore(props: string | string[], changes: any, item: T, notNull: boolean = false): boolean {
		if (typeof props === 'string') {
			props = [props];
		}

		return false;
	}

	private isObject(item) {
		return item && typeof item === 'object' && !Array.isArray(item);
	}

	private makeArray(items: any): any[] {
		if (!_.isArray(items)) {
			items = [items];
		}

		return items;
	}

	private mergeDeep(target, ...sources) {
		if (!sources.length) return target;
		const source = sources.shift();

		if (this.isObject(target) && this.isObject(source)) {
			for (const key in source) {
				if (this.isObject(source[key])) {
					if (!target[key]) Object.assign(target, { [key]: {} });
					this.mergeDeep(target[key], source[key]);
				} else {
					Object.assign(target, { [key]: source[key] });
				}
			}
		}

		return this.mergeDeep(target, ...sources);
	}
}

export class ChangeDefinition<T> {
	public callback: (item: T) => void;
	public confirm?: string | string[];
	public ignore?: string | string[];
	public immediate?: boolean = true;
	public notNull?: boolean = false;

	constructor(callback: (item: T) => void, confirm?: string | string[], ignore?: string | string[], immediate: boolean = true, notNull: boolean = false) {
		this.callback = callback;
		this.confirm = confirm;
		this.ignore = ignore;
		this.immediate = immediate;
		this.notNull = notNull;
	}
}
