import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { environment } from '../../environments/environment';
import { DisplayCardComponent } from '../components/display-card/display-card.component';
import { DisplayDetailPageComponent } from '../components/display-detail-page/display-detail-page.component';
import { DisplayFormPageComponent } from '../components/display-form-page/display-form-page.component';
import { DisplayFormComponent } from '../components/display-form/display-form.component';
import { DisplayPageComponent } from '../components/display-page/display-page.component';
import { FooterComponent } from '../components/footer/footer.component';
import { HomePageComponent } from '../components/home-page/home-page.component';
import { ImageUploadComponent } from '../components/image-upload/image-upload.component';
import { LoginFormComponent } from '../components/login-form/login-form.component';
import { LoginPageComponent } from '../components/login-page/login-page.component';
import { MainComponent } from '../components/main/main.component';
import { NavigationComponent } from '../components/navigation/navigation.component';
import { RegisterFormComponent } from '../components/register-form/register-form.component';
import { RegisterPageComponent } from '../components/register-page/register-page.component';
import { StoryComponent } from '../components/story/story.component';
import { TagInputComponent } from '../components/tag-input/tag-input.component';
import { UserFavoritesPageComponent } from '../components/user-favorites-page/user-favorites-page.component';
import { UserChatListItemComponent } from '../components/user-plant-mail-page/user-chat-list-item.component';
import { UserChatComponent } from '../components/user-plant-mail-page/user-chat.component';
import { UserPlantMailPageComponent } from '../components/user-plant-mail-page/user-plant-mail-page.component';
import { UserProfileCardSmallComponent } from '../components/user-profile-card/user-profile-card-small.component';
import { UserProfileCardComponent } from '../components/user-profile-card/user-profile-card.component';
import { UserProfileEditFormComponent } from '../components/user-profile-edit-form/user-profile-edit-form.component';
import { UserProfileEditPageComponent } from '../components/user-profile-edit-page/user-profile-edit-page.component';
import { UserProfilePageComponent } from '../components/user-profile-page/user-profile-page.component';
import { UserRatingComponent } from '../components/user-rating-bar/user-rating.component';
import { DragDirective } from '../directives/dragDrop.directive';
import { AuthGuard } from '../guards/auth.guard';
import { PageGuard } from '../guards/page.guard';
import { AuthService } from '../services/auth.service';
import { ChangeService } from '../services/change.service';
import { EnvironmentService } from '../services/environment.service';
import { FormatterService } from '../services/formatter.service';
import { LoaderService } from '../services/loader.service';
import { MetaService } from '../services/meta.service';
import { NotificationService } from '../services/notification.service';
import { PageService } from '../services/page.service';
import { RegisterService } from '../services/register.service';
import { StateService } from '../services/state.service';
import { SubscriptionService } from '../services/subscription.service';
import { TraversalService } from '../services/traversal.service';
import { UpdateService } from '../services/update.service';
import { UriService } from '../services/uri.service';
import { RoutingModule } from './routing.module';


@NgModule({
	declarations: [
		DisplayPageComponent,
		ImageUploadComponent,
		TagInputComponent,
		DisplayCardComponent,
		UserPlantMailPageComponent,
		UserFavoritesPageComponent,
		UserProfilePageComponent,
		UserProfileEditFormComponent,
		UserProfileEditPageComponent,
		UserProfileCardComponent,
		UserProfileCardSmallComponent,
		LoginFormComponent,
		LoginPageComponent,
		MainComponent,
		StoryComponent,
		NavigationComponent,
		FooterComponent,
		HomePageComponent,
		RegisterPageComponent,
		RegisterFormComponent,
		DisplayFormComponent,
		DisplayFormPageComponent,
		DisplayDetailPageComponent,
		UserRatingComponent,
		UserChatListItemComponent,
		UserChatComponent,
		DragDirective
	],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		BrowserModule,
		HttpClientModule,
		RoutingModule,
		MatSnackBarModule,
		MatIconModule,
		
		
		LazyLoadImageModule,
		BrowserAnimationsModule,
		

		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
	],
	providers: [
		AuthService,
		AuthGuard,
		MetaService,
		NotificationService,
		UpdateService,
		PageGuard,
		PageService,
		ChangeService,
		EnvironmentService,
		LoaderService,
		StateService,
		SubscriptionService,
		TraversalService,
		FormatterService,
		UriService,
		RegisterService,
	],
	bootstrap: [MainComponent],
})
export class PropstatModule {}
