import { Directive } from '@angular/core';
import { PageComponent } from './page.component';

@Directive()
export abstract class DisplayDetailComponent extends PageComponent {
	/* public isError$: boolean = false;
	public isLoaded$: boolean = false;
	public restaurantDishes: IRestaurantDish[] = [];

	constructor(stateService: StateService, private activatedRoute: ActivatedRoute) {
		super(stateService);
	} */

	/* protected onInit(): Promise<void> {
		super.onInit();
		this.isLoaded$ = false;
		this.isError$ = false;

		return Promise.all([
			this.restaurantDishService
				.get()
				.then((restaurantDishes: IRestaurantDish[]) => (this.restaurantDishes = restaurantDishes))
				.finally(() => (this.isLoaded$ = true))
				.then(),
			this.dishService.get().then((dishes: IDish[]) => dishes),
		]).then(result => {
			this.activatedRoute.paramMap.subscribe(params => {
				const dish = result[1].find(d => d.slug === params.get('slug'));

				if (dish != null) {
					this.restaurantDish = this.restaurantDishes.find(rd => rd.dish.id === dish.id);
				} else {
					this.isError$ = true;
				}
			});
		});
	} */
}
