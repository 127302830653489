import { Component } from '@angular/core';
import { StateService } from 'src/propstat/services/state.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-user-profile-card-small',
	templateUrl: './user-profile-card-small.component.html',
})
export class UserProfileCardSmallComponent extends BaseComponent {

	constructor(stateService: StateService) {
		super(stateService);
	}

}
