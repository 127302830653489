
<div class="uk-container uk-margin-large-top">
     <div class="uk-child-width-1-2@xs uk-child-width-1-3@m" uk-grid>
        <prop-stat-display-card></prop-stat-display-card>
        <prop-stat-display-card></prop-stat-display-card>
        <prop-stat-display-card></prop-stat-display-card>
        <prop-stat-display-card></prop-stat-display-card>
        <prop-stat-display-card></prop-stat-display-card>
        <prop-stat-display-card></prop-stat-display-card>
        <prop-stat-display-card></prop-stat-display-card>
        <prop-stat-display-card></prop-stat-display-card>
    </div>
</div>
