import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRegisterResponse } from '../interfaces/registerresponse';
import { IUser } from '../interfaces/user';
import { AuthService } from './auth.service';
import { EntityService } from './entity.service';
import { EnvironmentService } from './environment.service';
import { LoaderService } from './loader.service';
import { StateService } from './state.service';

@Injectable()
export class RegisterService {
	constructor(protected environmentService: EnvironmentService, protected authService: AuthService, protected stateService: StateService, protected loaderService: LoaderService, protected httpClient: HttpClient) {
	}

	public register(user: any) {
		return new Promise<IRegisterResponse>(async (resolve, reject) => {
			let url = this.getUrl();
			
			this.loaderService.set(true);

			return this.httpClient
				.post<IRegisterResponse>(url, user)
				.subscribe(
					(response: IRegisterResponse) => {
						resolve(response);
						this.loaderService.set(false);
					},
					(error: any) => {
						reject(error.message);
						this.loaderService.set(false);
					}
				);
		});
	}

	private getUrl(): string {
		return `${this.environmentService.current$.serverUrl}/users`;
	}
}