<div class="uk-navbar-container">
    <div class="uk-container">
        <nav class="uk-padding-small" uk-navbar>
            <div class="uk-navbar-left">
                <a [routerLink]="['/app']"><img src="../assets/img/pflanze1.png" width="50"></a>
            </div>
            <div class="uk-navbar-center">
                <div class="uk-navbar-item">
                    <form class="uk-width-1-1 uk-search uk-search-default uk-background-default uk-border-rounded">
                        <a href="" class="uk-search-icon-flip " uk-search-icon></a>
                        <input class="uk-search-input uk-border-rounded uk-form-width-large" type="search" placeholder="Search...">
                    </form>
                </div>
                <button class="uk-button uk-button-primary" routerLink="/display-create"><span uk-icon="icon: plus-circle; ratio: 0.8"></span>  Anzeige</button>
            </div>
            <div class="uk-navbar-right">
                <ul class="uk-iconnav">
                    <li>
                        <a routerLink="/plant-mail">
                            <mat-icon>forum</mat-icon>
                            <span class="uk-badge-message">10</span>
                        </a>
                    </li>
                    <li><a routerLink="/my-favourites"><mat-icon>favorite_border</mat-icon></a></li>
                    <li><a routerLink="/my-profile"><mat-icon>face</mat-icon></a></li>
                    <li><a routerLink="/logout"><mat-icon>logout</mat-icon></a></li>
                    <li><a routerLink="/logout"><mat-icon>login</mat-icon></a></li>
                </ul>
            </div>
        </nav>
    </div>
</div>