<div class="uk-container uk-margin-large-top">
    <div class="uk-flex uk-background-default uk-padding uk-border-rounded">
        <div uk-grid>
            <div class="uk-width-1-2@m">

                <div class="uk-position-relative uk-visible-toggle uk-light uk-border-rounded" tabindex="-1" uk-slider>
                    <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-1@m uk-grid ">
                        <li *ngFor="let img of displayImages">
                            <div class="uk-panel uk-height-large uk-flex uk-flex-middle">
                                <img src={{img}} alt="">
                            </div>
                        </li>
                    </ul>
                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
                </div>

            </div>
            <div class="uk-width-1-2@m">
                <h2>{{title}}</h2>
                <div uk-grid>
                    <div class="uk-width-1-2@m">
                    <h3>{{price}}€</h3>
                    </div>
                    <div class="uk-width-expand@m uk-text-right">
                    <span *ngIf="isShippingPossible" class="uk-label"><mat-icon>local_shipping</mat-icon></span>
                    <span *ngIf="isTradePossible" class="uk-label"><mat-icon>cached</mat-icon></span>
                    </div>
                </div>

                <hr>
                <p class="uk-text-justify">{{description}}</p>
                <span *ngFor="let tag of tagList" class="uk-label-tag">{{tag.name}}</span>
                
                <div class="uk-margin-medium-top">
				    <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" [routerLink]="['/register']">Mit @jvlixi chatten</button>
                    <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" [routerLink]="['/register']">Kaufen</button>
                </div>
                <prop-stat-user-profile-card-small [routerLink]="['/my-profile']"></prop-stat-user-profile-card-small>
            </div>
        </div>
    </div>
</div>