import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IState, State } from '../interfaces/state';
import { ChangeDefinition, ChangeService } from './change.service';
import { EnvironmentService } from './environment.service';
import { SubscriptionService } from './subscription.service';

@Injectable()
export class StateService {
	private storageName: string;

	constructor(private subscriptionService: SubscriptionService, private changeService: ChangeService<IState>, private environmentService: EnvironmentService) {
		this.storageName = `${this.environmentService.current$.appName}-state`;
		this.changeService.next(null, this.getOrCreateFromStorage());
	}

	public get current$(): IState {
		return this.changeService.current$;
	}

	public onChange(component: any, callback: (state: IState) => void, confirm?: string | string[], ignore?: string | string[], immediate: boolean = true, notNull: boolean = false): void {
		var changeDefinition = new ChangeDefinition<IState>(callback, confirm, ignore, immediate, notNull);
		this.onChangeInternal(component, changeDefinition);
	}

	public onChangeInternal(component: any, changeDefinition: ChangeDefinition<IState>): void {
		var subscription = this.changeService.subscribe(changeDefinition);
		this.subscriptionService.add(component, subscription);
	}

	public onChanges(component: any, changeDefinitions: ChangeDefinition<IState>[]): void {
		changeDefinitions.forEach(changeDefinition => {
			this.onChangeInternal(component, changeDefinition);
		});
	}

	public onDestroy(component: any): void {
		this.subscriptionService.remove(component);
	}

	public reset(): void {
		console.log('Resetting application state');
		window.localStorage.clear();
		this.update(state => this.create());
	}

	public update(setter: (state: IState) => void, notify: boolean = true): void {
		var current = this.current$;
		var oldItem = _.cloneDeep(current);
		setter(current as IState);
		var newItem = _.cloneDeep(current);

		if (notify) {
			this.changeService.next(oldItem, newItem);
		}

		this.updateStorage(newItem);
	}

	private create(): IState {
		return new State();
	}

	private getOrCreateFromStorage(): IState {
		var storedSettings = window.localStorage.getItem(this.storageName);
		var state: IState = null;

		if (storedSettings) {
			state = JSON.parse(storedSettings);
			state.isLoading = false;
		}

		if (state == null || this.hasStructureChanged(state)) {
			state = this.create();
			console.log('Creating new application state:', state);
			this.updateStorage(state);
		}

		return state;
	}

	private hasStructureChanged(state: IState): boolean {
		return !this.objectKeysEqual(this.create(), state);
	}

	private objectKeysEqual(obj1: any, obj2: any): boolean {
		if (obj1 == null || obj2 == null) {
			return true;
		}

		for (const key in obj1) {
			if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
				return false;
			}

			if (_.isObject(obj1[key]) && !_.isArray(obj1[key]) && !this.objectKeysEqual(obj1[key], obj2[key])) {
				return false;
			}
		}

		return true;
	}

	private updateStorage(state: IState): void {
		window.localStorage.setItem(this.storageName, JSON.stringify(state));
	}
}
