<div class="uk-cursor-pointer" uk-grid>
    <div class="uk-width-auto">
        <img [routerLink]="['/my-profile']" class="uk-border-circle uk-background-primary uk-profile-picture uk-cursor-pointer" src={{userProfilePicture}} >
    </div>
    <div class="uk-width-expand ">
        <span class="uk-margin-remove-bottom">@jvlixi</span>
        <p class="uk-text-meta uk-margin-remove-top">Würdest du mir die Pflanze auch etwas günstiger verkaufen?</p>
    </div>
    <div class="uk-width-1-6">
        <p class="uk-text-meta uk-margin-remove-top">19.08.23 18:30</p>
    </div>
</div>
