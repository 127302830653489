import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StateService } from 'src/propstat/services/state.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-display-card',
	templateUrl: './display-card.component.html',
})
export class DisplayCardComponent extends BaseComponent {

	@Input() selected: boolean;
  	@Output() selectedChange = new EventEmitter<boolean>();

	public userProfileName: string = "jvlixi";
	public userCity: string = "Sundern";
	public userProfilePicture: string = "../assets/img/93eaef67-d29d-4757-ae7c-22d1815a6c3e.JPG";

	public plantDisplayCardPicture: string = "../assets/img/2E3E093E-FD71-4C7C-A336-9E11DE017832.JPG";
	public plantDisplayCardPrice: number = 20.55;
	public plantDisplayCardTitle: string = "Drachenbaum";
	public isShippingPossible: boolean = true;
	public isTradePossible: boolean = true;

	constructor(stateService: StateService) {
		super(stateService);
	}

	public toggleSelected() {
		this.selected = !this.selected;
		this.selectedChange.emit(this.selected);
	}

	
}
