import { Injectable } from '@angular/core';

@Injectable()
export class FormatterService {
	public currency(value: number): string {
		return this.format(value, 2, 2);
	}

	public relation(value: number): string {
		return this.format(Math.round(value * 100), 0, 0);
	}

	private format(value: number, minDigits: number, maxDigits: number): string {
		return new Intl.NumberFormat('de-DE', {
			maximumFractionDigits: minDigits,
			minimumFractionDigits: maxDigits,
		}).format(value);
	}
}
