import { Component } from '@angular/core';
import { StateService } from 'src/propstat/services/state.service';
import { PageComponent } from '../page.component';


@Component({
	selector: 'prop-stat-user-plant-mail-page',
	templateUrl: './user-plant-mail-page.component.html',
})
export class UserPlantMailPageComponent extends PageComponent {
	constructor(stateService: StateService) {
		super(stateService);
	}
}
