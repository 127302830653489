
    <div class="uk-box-shadow-large uk-border-rounded">
    <div class="uk-card-header uk-background-default uk-border-rounded-top">
        <div class="uk-grid-small uk-flex-middle" uk-grid>
            <div  class="uk-width-auto">
                <img [routerLink]="['/my-profile']" class="uk-border-circle uk-background-primary uk-profile-picture" src={{userProfilePicture}} >
            </div>
            <div class="uk-width-expand">
                <h4 class="uk-card-title uk-margin-remove-bottom">@ {{userProfileName}}</h4>
                <p class="uk-text-meta uk-margin-remove-top">
                    <span uk-icon="icon:  location"></span>
                    {{userCity}}
                </p>
            </div>
        </div>
    </div>
    <div class="">
        <div [routerLink]="['/app/detail']"  class="uk-inline-clip uk-transition-toggle uk-height-medium uk-flex uk-flex-middle"  tabindex="0">
            <img class="uk-transition-scale-up uk-transition-opaque uk-card-picture" src={{plantDisplayCardPicture}} alt="">
        </div>
    </div>
    <div class="uk-card-body uk-background-default uk-border-rounded-bottom">
        <h3 class="uk-card-title uk-margin-remove-bottom">{{plantDisplayCardPrice }}€</h3>
        <span class="">{{plantDisplayCardTitle}}</span>
        <br>
        <br>
        <div uk-grid>
            <div class="uk-width-1-2">
                <span *ngIf="isShippingPossible" class="uk-label"><mat-icon>local_shipping</mat-icon></span>
                <span *ngIf="isTradePossible" class="uk-label"><mat-icon>cached</mat-icon></span>
           </div>
           <div class="uk-width-1-2">
                <div class="uk-flex uk-flex-right">
                    <button class="uk-fav-button uk-cursor-pointer" (click)="toggleSelected()">
                        <mat-icon *ngIf="selected">favorite</mat-icon>
                        <mat-icon *ngIf="!selected">favorite_border</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>