<div class="uk-box-shadow-medium uk-background-default  uk-border-rounded uk-cursor-pointer">
    <div class="uk-grid-small uk-flex-middle" uk-grid>
        <div class="uk-width-auto uk-padding">
            <img class="uk-profile-card-picture uk-border-circle" src="../assets/img/93eaef67-d29d-4757-ae7c-22d1815a6c3e.JPG">
        </div>
        <div class="uk-width-expand">
            <h3 class="uk-card-title uk-margin-remove-bottom">@ jvlixi</h3>
            <p class="uk-text-meta uk-margin-remove-top"><prop-stat-user-rating></prop-stat-user-rating></p>
        </div>
        <div class="uk-width-1-6">
            <mat-icon class="uk-icon-button" [routerLink]="['/my-profile']" appearance="rounded">arrow_right</mat-icon>
        </div>
    </div>
</div>