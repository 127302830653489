import { Component } from '@angular/core';
import { StateService } from '../../services/state.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-navigation',
	templateUrl: './navigation.component.html',
})
export class NavigationComponent extends BaseComponent {


	constructor(stateService: StateService) {
		super(stateService);
	}
}
