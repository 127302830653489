import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import * as moment from 'moment';
import { IAuthResponse } from '../interfaces/authresponse';
import { EnvironmentService } from './environment.service';
import { LoaderService } from './loader.service';
import { StateService } from './state.service';

@Injectable()
export class AuthService {
	private tokenName: string;

	constructor(protected environmentService: EnvironmentService, protected stateService: StateService, protected loaderService: LoaderService, private httpClient: HttpClient) {
		this.tokenName = `${this.environmentService.current$.appName}-auth`;
	}

	public clearToken(): void {
		return window.localStorage.removeItem(this.tokenName);
	}

	public getToken(): string {
		return window.localStorage.getItem(this.tokenName);
	}

	public isLoggedIn(): boolean {
		var authToken = this.getToken();
		return authToken != null && this.parseTokenValidity(authToken);
	}

	public login(email: string, password: string): Promise<IAuthResponse> {
		this.loaderService.set(true);

		return new Promise<IAuthResponse>(async (resolve, reject) => {
			let url = this.getUrl();

			return this.httpClient
				.post<IAuthResponse>(url, { identifier: email, password: password })
				.subscribe(
					(response: IAuthResponse) => {
						resolve(response);
						this.setToken(response.jwt);
						this.loaderService.set(false);
					},
					(error: any) => {
						reject(error.message);
						this.loaderService.set(false);
					}
				);
		});
	}

	public setToken(token: string): void {
		return window.localStorage.setItem(this.tokenName, token);
	}

	private getUrl(): string {
		return `${this.environmentService.current$.serverUrl}/auth/local`;
	}

	private parseTokenValidity(authToken: string): boolean {
		let jwt: any = jwt_decode(authToken);
		var now = moment.utc();
		var issuedAt = moment.utc(jwt.iat * 1000);
		var expiry = moment.utc(jwt.exp * 1000);
		var isValid = now.isBetween(issuedAt, expiry, null, '[]');

		return true;
	}
}
