import { IPage } from './page';

export interface IState {
	page: IPage;
	isLoading: boolean;
}

export class State implements IState {
	public page: IPage = null;
	public isLoading: boolean = false;
}
