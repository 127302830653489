import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { StateService } from './state.service';

@Injectable()
export class UpdateService {
	constructor(private appRef: ApplicationRef, private stateService: StateService, private serviceWorker: SwUpdate, private notificationService: NotificationService) {}

	public checkForUpdates(minutes: number): void {
		if (!this.serviceWorker.isEnabled) {
			return;
		}

		const appIsStable = this.appRef.isStable.pipe(first(isStable => isStable === true));
		const everyMinutes = interval(minutes * 60 * 1000);
		concat(appIsStable, everyMinutes).subscribe(() => this.serviceWorker.checkForUpdate());

		this.serviceWorker.available.subscribe(event => {
			this.notificationService.notify({ message: 'Es ist ein Update verfügbar', duration: 8, action: 'Aktualisieren' }).then(() => {
				this.serviceWorker.activateUpdate().then(() => {
					this.stateService.reset();
					setTimeout(() => document.location.reload(), 500);
				});
			});
		});
	}
}
