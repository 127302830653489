import { NotImplementedError } from '../errors/not-implemented.error';
import { IEntity } from '../interfaces/entity';

export abstract class StaticService<TEntity extends IEntity> {
	constructor(protected entities: TEntity[]) {}

	public delete(query: string = null): Promise<void> {
		throw new NotImplementedError();
	}

	public get(query: string = null): Promise<TEntity[] | TEntity> {
		return new Promise<TEntity[] | TEntity>(async (resolve, reject) => {
			if (query == null) {
				resolve(this.entities);
			} else {
				resolve(this.entities.find(e => e.id === query));
			}
		});
	}

	public post(body: any | null, query: string = null): Promise<TEntity[] | TEntity> {
		throw new NotImplementedError();
	}

	public put(id: string, body: any | null): Promise<TEntity[] | TEntity> {
		throw new NotImplementedError();
	}
}
