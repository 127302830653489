<div class="uk-box-shadow-large uk-border-rounded">
    <div class="uk-card uk-background-default uk-border-rounded">
        <div class="uk-card-header">

            <a class="uk-flex uk-flex-right"><mat-icon [routerLink]="['/edit-profile']" appearance="rounded">edit</mat-icon></a>
            <div class="uk-flex uk-flex-middle uk-flex-center">
                    <img class="uk-border-circle uk-profile-card-picture"  src="../assets/img/93eaef67-d29d-4757-ae7c-22d1815a6c3e.JPG">
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-center uk-margin-small-top">
                <div class="uk-card-title">@jvlixi</div>
            </div>
        </div>
        <div class="uk-card-body ">
           <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquy</p>
            <hr class="uk-divider-icon">
            <p><prop-stat-user-rating></prop-stat-user-rating></p>
            <button class="uk-button uk-button-primary uk-width-1-1" routerLink="/display-create">Follow</button>
        </div>
    </div>
</div><br> 