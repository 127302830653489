<div class="uk-container uk-margin-large-top">
    <div class="uk-card uk-background-default uk-border-rounded">
        <div class="uk-card-body ">
        <legend class="uk-legend uk-h2">Deine Plant Mail</legend>
        <ul class="uk-list uk-list-large uk-list-striped">
            <li [routerLink]="['/plant-mail/chat/username']" ><prop-stat-user-chat-list-item></prop-stat-user-chat-list-item></li>
            <li><prop-stat-user-chat-list-item></prop-stat-user-chat-list-item></li>
            <li><prop-stat-user-chat-list-item></prop-stat-user-chat-list-item></li>
        </ul>
        </div>
    </div>
</div>