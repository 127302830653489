import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class UriService {
	constructor(private urlEncoder: HttpUrlEncodingCodec) {}

	public encode(value: string): string {
		return this.urlEncoder.encodeValue(value).toLowerCase();
	}

	public decode(value: string): string {
		return this.urlEncoder.decodeValue(value);
	}
}
