import { Injectable } from '@angular/core';
import { IPage } from '../interfaces/page';
import pages from '../static/page.json';
import { StaticService } from './static.service';

@Injectable()
export class PageService extends StaticService<IPage> {
	constructor() {
		super(pages as IPage[]);
	}

	public getFromList(entities: IPage[], query: string = null): Promise<IPage[] | IPage> {
		return new Promise<IPage[] | IPage>(async (resolve, reject) => {
			if (query == null) {
				resolve(entities);
			} else {
				resolve(entities.find(e => e.id === query));
			}
		});
	}

	public getFromPath(path: string[]): Promise<IPage[] | IPage> {
		return new Promise<IPage>(async (resolve, reject) => {
			let page = (await this.get(path[0])) as IPage;

			for (let i = 1; i < path.length; i++) {
				page = (await this.getFromList(page.children, path[i])) as IPage;
			}

			resolve(page);
		});
	}
}
