import { Component } from '@angular/core';
import { StateService } from 'src/propstat/services/state.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-footer',
	templateUrl: './footer.component.html',
})
export class FooterComponent extends BaseComponent {
	constructor(stateService: StateService) {
		super(stateService);
	}
}
