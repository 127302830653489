import { NgModule } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@NgModule()
export class SubscriptionService {
	private map: SubscriptionMap[] = [];

	private getMap(component: any): SubscriptionMap {
		var map = _.find(this.map, m => m.component == component);
		return map;
	}

	private makeArray(items: any): any[] {
		if (!_.isArray(items)) {
			items = [items];
		}

		return items;
	}

	public add(component: any, subscriptions: Subscription | Subscription[]): void {
		subscriptions = this.makeArray(subscriptions);
		var map = this.getMap(component);

		if (map == null) {
			map = new SubscriptionMap(component, subscriptions);
			this.map.push(map);
		} else {
			map.subscriptions = map.subscriptions.concat(subscriptions);
		}
	}

	public remove(component: any): void {
		var map = this.getMap(component);

		if (map != null) {
			map.subscriptions.forEach(s => {
				s.unsubscribe();
			});
			map.subscriptions = [];
		}
	}
}

export class SubscriptionMap {
	constructor(component: any = null, subscriptions: Subscription[] = []) {
		this.component = component;
		this.subscriptions = subscriptions;
	}

	public component: any;
	public subscriptions: Subscription[] = [];
}
