<div class="uk-container uk-margin-large-top">
    <div class="uk-card uk-background-default uk-border-rounded">
        <div class="uk-card-header ">
            <div class="uk-cursor-pointer" uk-grid>
                <div class="uk-width-auto">
                    <img [routerLink]="['/my-profile']" class="uk-border-circle uk-background-primary uk-profile-picture uk-cursor-pointer" src={{userProfilePicture}} >
                </div>
                <div class="uk-width-expand uk-flex uk-flex-middle">
                    <span class="uk-margin-remove-bottom">@jvlixi</span>
                </div>
            </div>
        </div>
        <div class="uk-card-body ">
            <div class="uk-chat-container uk-border-rounded">
                <div class="uk-flex uk-flex-left">
                    <p class="uk-label-chat-other"> hallo</p>
                </div>
                <div class="uk-flex uk-flex-right">
                    <p class="uk-label-chat-you"> hallo its me</p>
                </div>
            </div>
        </div>
         <div class="uk-card-footer ">
             <div uk-grid>
                 <div class="uk-width-expand">
                    <input class="uk-input uk-border-rounded" type="text" placeholder="Input"> 
                </div>  
                <div class="uk-width-1-6">                                 
                    <button class="uk-button uk-button-primary" >Senden</button>   
                </div>   
            </div>
        </div>
    </div>
</div>