import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'src/propstat/services/notification.service';
import { StateService } from 'src/propstat/services/state.service';
import { AuthService } from '../../services/auth.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-login-form',
	templateUrl: './login-form.component.html',
})
export class LoginFormComponent extends BaseComponent {
	public form: FormGroup;
	public agreement: boolean = false;
	public isLoading$: boolean = false;

	constructor(stateService: StateService, private formBuilder: FormBuilder, private authService: AuthService, private notificationService: NotificationService, private router: Router) {
		super(stateService);
	}

	public submit(): void {
		this.isLoading$ = true;

		this.authService
			.login(this.form.value.username, this.form.value.password)
			.then()
			.catch(() => {
				this.notificationService.notify({
					message: 'Benutzername und/oder Passwort stimmen nicht überein. Bitte versuche es erneut.',
					duration: 5,
				});
			})
			.finally(() => {
				console.log(this.authService.isLoggedIn());
				this.isLoading$ = false;
				this.router.navigate(['/app']);
			});
	}

	protected onInit(): void {

		if(this.authService.isLoggedIn()) {
			this.router.navigate(['/app']);
		}

		this.form = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
		});
	}
}
