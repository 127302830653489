import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { PropstatModule } from './propstat/modules/propstat.module';


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(PropstatModule)
  .catch(err => console.error(err));
