import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
	protected loginRoute: string = '/login';

	constructor(private authService: AuthService, private router: Router) {}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		if (!this.authService.isLoggedIn()) {
			this.router.navigate([this.loginRoute], {
				queryParams: {
					return: state.url,
				},
			});
		} else {
			return true;
		}
	}
}
