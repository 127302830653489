<div class="uk-flex uk-flex-column uk-background-default uk-padding uk-border-rounded">
	<form class="" action="" [formGroup]="form">
		<fieldset class="uk-fieldset">
			<legend class="uk-legend uk-h2">Einloggen</legend>
			<div class="uk-margin">
				<input type="text" class="uk-input uk-border-rounded" placeholder="Benutzername" formControlName="username" />
			</div>
			<div class="uk-margin">
				<input type="password" class="uk-input uk-border-rounded" placeholder="Passwort" formControlName="password" />
            </div>
            <div class="uk-margin-medium-top">
				<button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" [disabled]="!form.valid || isLoading$" type="submit" (click)="submit()">Einloggen</button>
			</div>
			<hr class="uk-divider-icon">
			<div class="uk-margin-medium-top">
				<button class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom" [routerLink]="['/register']">Kostenlos Registrieren</button>
			</div>
		</fieldset>
	</form>
</div>