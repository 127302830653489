import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StateService } from './state.service';

@Injectable()
export class LoaderService {
	public current$: number = 0;

	private loaderCount: Subject<number> = new Subject();

	constructor(private stateService: StateService) {
		this.loaderCount.pipe(debounceTime(300)).subscribe(loaderCount => {
			this.stateService.update(state => (state.isLoading = loaderCount > 0));
		});
	}

	public end(): void {
		this.current$ = 0;
		this.loaderCount.next(this.current$);
	}

	public set(value: boolean): void {
		if (value) {
			this.current$ = Math.min(this.current$ + 1, 999);
		} else {
			this.current$ = Math.max(this.current$ - 1, 0);
		}

		this.loaderCount.next(this.current$);
	}
}
