import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { StateService } from 'src/propstat/services/state.service';
import { BaseComponent } from '../base.component';


@Component({
	selector: 'prop-stat-tag-input',
	templateUrl: './tag-input.component.html',
})
export class TagInputComponent extends BaseComponent {
	
	constructor(stateService: StateService, private http: HttpClient) {
		super(stateService);
	}
	
}
