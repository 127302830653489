import { Component } from '@angular/core';
import { StateService } from 'src/propstat/services/state.service';
import { PageComponent } from '../page.component';


@Component({
	selector: 'prop-stat-user-chat',
	templateUrl: './user-chat.component.html',
})
export class UserChatComponent extends PageComponent {
	public userProfilePicture: string = "../assets/img/93eaef67-d29d-4757-ae7c-22d1815a6c3e.JPG";

	constructor(stateService: StateService) {
		super(stateService);
	}
}
