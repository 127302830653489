import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { StateService } from './state.service';

@Injectable()
export class MetaService {
	public baseTitle$: string = null;

	constructor(private stateService: StateService, private title: Title, private meta: Meta) {}

	public init(): void {
		this.baseTitle$ = this.title.getTitle();
		this.stateService.onChange(
			this,
			state => {
				if (state.page != null) {
					this.setTitle(state.page.title);
					this.setKeywords(state.page.keywords);
					this.setDescription(state.page.description);
				}
			},
			'page'
		);
	}

	public setDescription(value: string): void {
		this.meta.updateTag({ name: 'description', content: value || '' });
	}

	public setKeywords(values: string[]): void {
		this.meta.updateTag({ name: 'keywords', content: values?.join(', ') || '' });
	}

	public setTitle(value: string): void {
		this.title.setTitle(`${this.baseTitle$} | ${value}`);
	}
}
