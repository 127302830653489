import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IPage } from '../interfaces/page';
import { RouteData } from '../interfaces/route';
import { MetaService } from '../services/meta.service';
import { PageService } from '../services/page.service';
import { StateService } from '../services/state.service';

@Injectable({ providedIn: 'root' })
export class PageGuard implements CanActivate {
	constructor(private stateSerivce: StateService, private pageService: PageService, private metaService: MetaService) {
		this.metaService.init();
	}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		this.pageService.getFromPath((next.data as RouteData).pageIdPath).then((page: IPage) => {
			this.stateSerivce.update(state => (state.page = page || null));
		});

		return true;
	}
}
